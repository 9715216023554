import Vue from 'vue';
import store from '@/store';

const NetworkError = Object.freeze({
  title: 'Erro de rede',
  text: 'Um erro ocorreu ao entrar em contato com o servidor. Tente novamente.',
  icon: 'error',
});

const ExpiredAccessTokenError = Object.freeze({
  title: 'Token de Acesso expirado',
  text: 'Seu Token de Acesso expirou. Por favor, faça login novamente!',
  icon: 'warning',
});

const ServerError = Object.freeze({
  title: 'Erro no servidor',
  text: 'Um erro ocorreu no servidor. Entre em contato com a adminstração.',
  icon: 'error',
});

const ValidationError = Object.freeze({
  title: 'Erro de validação',
  text: 'Um erro ocorreu ao validar os dados. Por favor, tente novamente.',
  icon: 'warning',
});

const ForbiddenError = Object.freeze({
  title: 'Acesso negado',
  text: 'Vocé não tem permissão para realizar esta ação.',
  icon: 'warning',
});

const StandardError = { title: 'Ops!', text: 'Um erro ocorreu. Por favor, tente novamente.', icon: 'error' };

const handleError = async (error, options = {}) => {
  // Log the error to the console
  if (error.message === 'Network Error') {
    let img = require('@/assets/images/disconected_icon.svg')
    await Vue.prototype.$swal({
      title: 'Não foi possível estabelecer conexão',
      text: 'Verifique se você está conectado com a internet e tente novamente em seguida.',
      iconHtml: `<img src=${img} width=125 height=39>`,
      customClass: {
        icon: 'no-border'
      },
    })
  }

  const errorObject = {
    500: {
      title: 'Algo deu errado!',
      message: 'A ação não pode ser executada. Tente novamente ou entre em contato com um de nossos administradores.',
    },
    502: {
      title: 'Algo deu errado!',
      message: 'A ação não pode ser executada. Tente novamente ou entre em contato com um de nossos administradores.',
    },
    503: {
      title: 'Algo deu errado!',
      message: 'A ação não pode ser executada. Tente novamente ou entre em contato com um de nossos administradores.',
    },
    504: {
      title: 'Não foi possível estabelecer conexão',
      message: 'Verifique se você está conectado com a internet e tente novamente em seguida.',
    },
    400: {
      title: 'Algo deu errado!',
      message: 'A ação não pode ser executada. Tente novamente ou entre em contato com um de nossos administradores.',
    },
    403: {
      title: 'Acesso negado',
      message: 'Você não tem permissão para acessar essa página ou recurso da plataforma.',
    },
    404: {
      title: 'Algo deu errado!',
      message: 'A ação não pode ser executada. Tente novamente ou entre em contato com um de nossos administradores.',
    },
    422: {
      title: 'Erro no formulário',
      message: 'Erro no servidor, entre em contato com a adminstração',
    },
    0: {
      title: 'Algo deu errado!',
      message: 'A ação não pode ser executada. Tente novamente ou entre em contato com um de nossos administradores.'
    }
  }

  let title = '';
  let text = '';

  if(errorObject[error.response.status]) {
    title = errorObject[error.response.status].title;
    text = errorObject[error.response.status].message;
  } else {
    title = errorObject[0].title;
    text = errorObject[0].message;
  }

  if ([422, 503].includes(error.response.status)) {
    let errorString = '';
    try {
      errorString =  error.response.data.errors.map((obj) => `<li>${obj}</li>`).join('');
    } catch (e) {
      errorString =  error.response.data.map((obj) => `<li>${obj}</li>`).join('');
    }
    await Vue.prototype.$swal({
      title,
      html: `<ul class="text-left" style="list-style-type:none">${errorString}</ul>`,
      icon: 'error'
    });
  } else {
    await Vue.prototype.$swal.fire({
      title: errorObject[error.response.status].title,
      html: errorObject[error.response.status].message,
      icon: 'error',
    })
  }
};

export default async function handleErrors(error) {
  handleError(error)
}
