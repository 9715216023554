import http from './http'

class AdminUser {
  fetchAll(payload = {}) {
    return http.get('/backoffice/admin/users', { params: payload })
  }

  fetch(id, payload = {}) {
    console.log('fetchUser', id, payload)
    return http.get(`/backoffice/admin/users/${id}`, { params: payload })
  }

  create(payload) {
    return http.post(`/backoffice/admin/users`, { user: payload })
  }
  
  update(id, payload) {
    return http.put(`/backoffice/admin/users/${id}`, { user: payload })
  }

  blockUsers(payload) {
    return http.post(`/backoffice/admin/users/block`, { user: { ids: payload } })
  }

  unblockUsers(payload) {
    return http.post(`/backoffice/admin/users/unblock`, { user: { ids: payload } })
  }
}

export default new AdminUser()
