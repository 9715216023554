import Ur from "@/services/ur";
import liquidation from "@/services/liquidation"
import CreditCardBrand from "@/services/credit_card_brands";
import Acquirer from "@/services/acquirer";
import Assignor from "@/services/assignor";
import AdminUser from "@/services/adminUser";

const bankAccount = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    liquidation.bankAccount(id)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

const operationDetails = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    liquidation.operationDetails(id)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

const paymentPending = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    liquidation.paymentPending(id)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

const baixarCnab = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    liquidation.baixarCnab(id)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

const reportPayment = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    liquidation.reportPayment(id)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

const fetchUrs = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Ur.fetch(payload)
    .then(response => resolve(response))
    .catch(error => reject(error))
  })
}

const conciliateUrs = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Ur.conciliate(payload)
    .then(response => resolve(response))
    .catch(error => reject(error))
  })
}

const fetchCreditCardBrands = ({}) => {
  return new Promise((resolve, reject) => {
    CreditCardBrand.fetch()
    .then(response => resolve(response))
    .catch(error => reject(error))
  })
}

const fetchAcquirersWithoutPaginate = ({}) => {
  return new Promise((resolve, reject) => {
    Acquirer.withoutPaginate()
    .then(response => resolve(response))
    .catch(error => reject(error))
  })
}

const fetchAssignors = ({}) => {
  return new Promise((resolve, reject) => {
    Assignor.fetch()
    .then(response => resolve(response))
    .catch(error => reject(error))
  })
}

const fetchCreditCardBrandsByUrs = ({}) => {
  return new Promise((resolve, reject) => {
    CreditCardBrand.fetchByUrs()
    .then(response => resolve(response))
    .catch(error => reject(error))
  })
}

const fetchAcquirersWithoutPaginateByUrs = ({}) => {
  return new Promise((resolve, reject) => {
    Acquirer.withoutPaginateByUrs()
    .then(response => resolve(response))
    .catch(error => reject(error))
  })
}

const fetchAssignorsByUrs = ({}) => {
  return new Promise((resolve, reject) => {
    Assignor.fetchByUrs()
    .then(response => resolve(response))
    .catch(error => reject(error))
  })
}

const fetchAllUsers = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    AdminUser.fetchAll(payload)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

const fetchUser = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    AdminUser.fetch(id)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

const createUser = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    AdminUser.create(payload)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

const updateUser = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    AdminUser.update(payload.id, payload)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

const blockUsers = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    AdminUser.blockUsers(payload)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

const unblockUsers = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    AdminUser.unblockUsers(payload)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export default {
  baixarCnab,
  reportPayment,
  paymentPending,
  bankAccount,
  conciliateUrs,
  fetchUrs,
  fetchCreditCardBrands,
  fetchAcquirersWithoutPaginate,
  fetchAssignors,
  operationDetails,
  fetchCreditCardBrandsByUrs,
  fetchAcquirersWithoutPaginateByUrs,
  fetchAssignorsByUrs,
  fetchUser,
  createUser,
  fetchAllUsers,
  blockUsers,
  unblockUsers,
  updateUser
}
