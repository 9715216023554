import {
  dateTimeFormatter,
  moneyFormatDatatable,
  creditSolicitationAdminStatusBadge,
  documentationStatusBadge,
  economicGroupNameLink,
  economicGroupPendingCompanies,
  cnpj,
  conviteStatusFormater,
  userTypeName,
  userStatusBadge,
} from '@core/comp-functions/data_visualization/datatable'

export default [
  {
    path: '/admin/empresas',
    name: 'admin-company',
    component: () => import('@/views/admin/companies/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Empresas',
      datatableTitle: 'Gerencie as empresas da plataforma',
      skipGoToHome: true,
      indexObject: {
        skipDefaultSort: true,
        datatableSearchable: true,
        serviceUrl: 'backoffice/cedentes',
        editRouteName: 'admin-company.general-data',
        fields: [
          {
            label: 'CNPJ',
            key: 'cnpj',
            sortable: true,
            formatter: cnpj,
          },
          {
            label: 'Razão social',
            key: 'razao_social',
            sortable: true,
          },
          {
            label: 'Grupo Econômico',
            key: 'grupo_economico',
            sortable: true,
            formatter: economicGroupNameLink,
          },
          {
            label: 'Status',
            key: 'status_documentacao',
            sortable: true,
            formatter: documentationStatusBadge,
          },
          {
            label: '',
            key: 'viewButton',
          },
        ],
        filters: [{
          fields: [
            {
              label: 'Status',
              key: 'status_documentacao',
              type: 'select',
              options: [
                {
                  label: 'Aprovado',
                  value: 'aprovado',
                },
                {
                  label: 'Reprovado',
                  value: 'reprovado',
                },
                {
                  label: 'Não enviado',
                  value: 'nao_enviado',
                },
                {
                  label: 'Aguardando análise',
                  value: 'em_validacao',
                },
              ],
            },
          ],
        }],
      },
    },
  },
  {
    path: '/admin/empresa/novo',
    component: () => import('@/layouts/vertical/LayoutVerticalAssignor.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
    children: [
      {
        path: '/',
        name: 'admin-company.new',
        component: () => import('@/views/admin/dashboard/pages/New.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
        },
      },
    ],
  },
  {
    path: '/admin/grupo-economico/:id',
    name: 'admin-economicGroup.show',
    component: () => import('@/views/admin/companies/pages/EconomicGroup.vue'),
    meta: {
      action: 'admin-read',
      pageTitle: 'Grupo econômico',
      pageSubtitle: 'Acesse abaixo as matrizes cadastradas neste grupo econômico',
      skipGoToHome: true,
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/grupo-economico/:id/matriz/:idMatriz',
    name: 'admin-economicGroup.matriz.show',
    component: () => import('@/layouts/vertical/LayoutVerticalAssignor.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
    children: [
      {
        path: 'dados-gerais',
        name: 'admin-economicGroup.general-data',
        component: () => import('@/views/admin/dashboard/pages/GeneralData.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
        },
      },
      {
        path: 'representantes',
        name: 'admin-economicGroup.representatives',
        component: () => import('@/views/admin/dashboard/pages/Representative.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
          title: 'Representantes legais',
        },
      },
      {
        path: 'documentos',
        name: 'admin-economicGroup.documents',
        component: () => import('@/views/admin/dashboard/pages/Documents.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
          title: 'Análise de documentos',
        },
      },
      {
        path: 'contas-bancarias',
        name: 'admin-economicGroup.bank-accounts',
        component: () => import('@/views/admin/dashboard/pages/BankAccount.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
          title: 'Dados bancários',
        },
      },
      {
        path: 'credenciadoras',
        name: 'admin-economicGroup.acquirers',
        component: () => import('@/views/admin/dashboard/pages/Acquirers.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
          title: 'Credenciadoras',
          subtitle: 'Selecione abaixo as credenciadoras a serem inseridas',
        },
      },
    ],
  },
  {
    path: '/admin/antecipacoes',
    name: 'admin-antecipacoes.index',
    component: () => import('@/views/admin/antecipacoes/pages/Index.vue'),
    meta: {
      pageTitle: 'Antecipações',
      indexObject: {
        serviceUrl: '/solicitacao_antecipacao/admin',
        fields: [
          {
            label: 'Operação',
            key: 'id',
            sortable: true,
          },
          {
            label: 'Grupo Econômico',
            key: 'grupo_economico',
            exportKey: 'grupo_economico.nome',
            sortable: true,
            formatter: economicGroupNameLink,
          },
          {
            label: 'Antecipação',
            key: 'created_at',
            sortable: true,
            formatter: dateTimeFormatter,
          },
          {
            label: 'Recebimento',
            key: 'status',
            sortable: true,
            formatter: creditSolicitationAdminStatusBadge,
          },
          {
            label: 'A antecipar',
            key: 'valor_liquido',
            formatter: moneyFormatDatatable,
          },
        ],
        actions: [
          {
            label: 'Visualizar',
            icon: 'icon-search',
            action: 'show',
            name: 'admin-antecipacoes.details',
          },
        ],
        filters: [
          {
            category: 'Por período',
            fields: [
              {
                label: 'Liquidação',
                key: 'data_liquidacao',
                type: 'date',
              },
            ],
          },
          {
            category: 'Por parâmetro',
            fields: [
              {
                label: 'Status',
                key: 'status',
                type: 'select',
                options: [
                  { label: 'Solicitada', value: '0' },
                  { label: 'Confirmada', value: '1' },
                  { label: 'Enviada CERC', value: '2' },
                  { label: 'Sucesso Webhook', value: '3' },
                  { label: 'Erro Webhook', value: '4' },
                  { label: 'Registrada', value: '5' },
                  { label: 'Depositada', value: '6' },
                  { label: 'Cancelada', value: '-1' },
                  { label: 'Erro Envio CERC', value: '-2' },
                ],
              },
              {
                label: 'Bandeira',
                key: 'credit_card_brand_id',
                type: 'select',
                action: 'common/getGenericOptionList',
                model: 'CreditCardBrand',
                modelLabel: 'name',
                modelValue: 'id',
                options: [],
              },
              {
                label: 'Credenciadora',
                key: 'credenciadora_id',
                type: 'select',
                action: 'common/getGenericOptionList',
                model: 'Credenciadora',
                modelLabel: 'razao_social',
                modelValue: 'id',
                options: [],
              },
            ],
          },
        ],
        editRouteName: 'admin-antecipacoes.details',
      },
      action: 'admin-read',
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/antecipacoes/:id/detalhes',
    name: 'admin-antecipacoes.details',
    component: () => import('@/views/admin/antecipacoes/pages/Details.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/credenciadoras',
    name: 'admin-credenciadoras.index',
    component: () => import('@/views/admin/acquirers/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Credenciadoras',
      indexObject: {
        datatableTitle: 'Confira abaixo as credenciadoras cadastradas',
      },
    },
  },
  {
    path: '/admin/credenciadoras/novo',
    name: 'admin-credenciadoras.new',
    component: () => import('@/views/admin/acquirers/pages/New.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/credenciadoras/:id',
    name: 'admin-credenciadoras.show',
    component: () => import('@/views/admin/acquirers/pages/Show.vue'),
    meta: {
      pageTitle: 'Editar credenciadora',
      pageSubtitle: 'Altere abaixo os dados cadastrais da credenciadora',
      skipGoToHome: true,
      action: 'admin-read',
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/operacoes',
    name: 'admin-operations.index',
    component: () => import('@/views/admin/liquidation/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Liquidar operações',
      pageSubtitle: 'Selecione abaixo as operações a serem liquidadas',
      indexObject: {
        serviceUrl: '/solicitacao_antecipacao/pendentes_pagamento',
        showModalOnAction: true,
        showExcelExportButton: true,
        fields: [
          {
            label: '',
            key: 'select',
          },
          {
            label: 'Operação',
            key: 'id',
            sortable: true,
          },
          {
            label: 'Grupo Econômico',
            key: 'grupo_economico',
            sortable: true,
            exportKey: 'grupo_economico.nome',
            formatter: economicGroupNameLink,
          },
          {
            label: 'Antecipação',
            key: 'created_at',
            sortable: true,
            formatter: dateTimeFormatter,
          },
          {
            label: 'Status',
            key: 'status',
            sortable: true,
            formatter: creditSolicitationAdminStatusBadge,
          },
          {
            label: 'A antecipar',
            key: 'valor_liquido',
            sortable: false,
            formatter: moneyFormatDatatable,
          },
        ],
        actions: [
          {
            label: 'Visualizar',
            icon: 'icon-search',
            action: 'show',
            name: 'OperationDetailModal',
          },
        ],
        filters: [
          {
            category: 'Por período',
            fields: [
              {
                label: 'Liquidação',
                key: 'data_liquidacao',
                type: 'date',
              },
            ],
          },
          {
            category: 'Por parâmetro',
            fields: [
              {
                label: 'Status',
                key: 'status',
                type: 'select',
                options: [
                  { label: 'Solicitada', value: '0' },
                  { label: 'Confirmada', value: '1' },
                  { label: 'Enviada CERC', value: '2' },
                  { label: 'Sucesso Webhook', value: '3' },
                  { label: 'Erro Webhook', value: '4' },
                  { label: 'Registrada', value: '5' },
                  { label: 'Depositada', value: '6' },
                  { label: 'Cancelada', value: '-1' },
                  { label: 'Erro Envio CERC', value: '-2' },
                ],
              },
              {
                label: 'Bandeira',
                key: 'credit_card_brand_id',
                type: 'select',
                action: 'common/getGenericOptionList',
                model: 'CreditCardBrand',
                modelLabel: 'name',
                modelValue: 'id',
                options: [],
              },
              {
                label: 'Credenciadora',
                key: 'credenciadora_id',
                type: 'select',
                action: 'common/getGenericOptionList',
                model: 'Credenciadora',
                modelLabel: 'razao_social',
                modelValue: 'id',
                options: [],
              },
            ],
          },
        ],
        actionsForSelectedItems: [
          {
            label: 'Baixar CNAB',
            icon: 'DownloadIcon',
            variant: 'flat-primary',
            disabled: true,
            event: 'baixar-CNAB',
          },
          {
            label: 'Informar pagamento',
            icon: 'DownloadIcon',
            variant: 'primary',
            disabled: true,
            event: 'informar-pagamento',
          },
        ],
      },
    },
  },
  {
    path: '/admin/conciliacao',
    name: 'admin-conciliation.index',
    component: () => import('@/views/admin/conciliation/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Conciliação de URs',
      pageSubtitle: 'Utilize os filtros abaixo para ajudar na seleção das URs de interesse',
    },
  },
  {
    path: '/admin/usuarios',
    name: 'admin-users.index',
    component: () => import('@/views/admin/users/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Listar usuários',
      pageSubtitle: 'Listagem de usuários cadastrados no sistema',
      editRouteName: 'admin-users.edit',
      indexObject: {
        serviceUrl: '/backoffice/admin/users',
        showModalOnAction: true,
        showExcelExportButton: false,
        fields: [
          {
            label: '',
            key: 'select',
          },
          {
            label: 'Nome',
            key: 'name',
            sortable: false,
          },
          {
            label: 'Email',
            key: 'email',
            sortable: false,
          },
          {
            label: 'Tipo',
            key: 'user_type',
            sortable: false,
            formatter: userTypeName,
          },
          {
            label: 'Grupo Econômico',
            key: 'grupo_economico',
            sortable: false,
            exportKey: 'grupo_economico.nome',
            formatter: economicGroupNameLink,
          },
          {
            label: 'Status',
            key: 'status',
            sortable: false,
            formatter: userStatusBadge,
          },
        ],
        actions: [
          {
            label: 'Visualizar',
            icon: 'icon-search',
            action: 'show',
            name: 'admin-users.edit',
          },
        ],
        filters: [
          {
            category: 'Por parâmetro',
            fields: [
              {
                label: 'Nome',
                key: 'name',
                type: 'text',
              },
              {
                label: 'E-mail',
                key: 'email',
                type: 'text',
              },
              {
                label: 'Tipo',
                key: 'user_type',
                type: 'select',
                options: [
                  { label: 'Administrador', value: 'admin' },
                  { label: 'Api', value: 'api' },
                  { label: 'Cedente', value: 'cedente' },
                  { label: 'Parceiro', value: 'parceiro' },
                ],
              },
              {
                label: 'Grupo Econômico',
                key: 'grupo_economico_id',
                type: 'select',
                action: 'common/getGenericOptionList',
                model: 'Backoffice::GrupoEconomico',
                modelLabel: 'nome',
                modelValue: 'id',
                options: [],
              },
              {
                label: 'Status',
                key: 'status',
                type: 'select',
                options: [
                  { label: 'Ativo', value: 'active' },
                  { label: 'Inativo', value: 'inactive' },
                  { label: 'Bloqueado', value: 'blocked' },
                ],
              },
            ],
          },
        ],
        actionsForSelectedItems: [
          {
            label: 'Bloquear',
            icon: 'LockIcon',
            variant: 'flat-primary',
            disabled: true,
            event: 'block-users',
          },
          {
            label: 'Desbloquear',
            icon: 'UnlockIcon',
            variant: 'flat-primary',
            disabled: true,
            event: 'unblock-users',
          },
        ],
      },
    },
  },
  {
    path: '/admin/usuarios/create',
    name: 'admin-users.create',
    component: () => import('@/views/admin/users/pages/Create.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Criar usuário',
      pageSubtitle: 'Cadastrar novo usuário admin no sistema',
    },
  },
  {
    path: '/admin/usuarios/edit/:id',
    name: 'admin-users.edit',
    component: () => import('@/views/admin/users/pages/Edit.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Editar usuário',
      pageSubtitle: 'Editar usuário no sistema',
    },
  },
  {
    path: '/admin/convites',
    name: 'admin-convites.index',
    component: () => import('@/views/admin/convite/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Convites',
      indexObject: {
        serviceUrl: '/backoffice/admin/convites',
        showModalOnAction: true,
        showExcelExportButton: true,
        actionType: 'copy',
        copyMessage: 'Link do convite</br>Clique para copiar',
        fields: [
          {
            label: '',
            key: 'select',
          },
          {
            label: 'Nome',
            key: 'nome_contato',
            sortable: true,
          },
          {
            label: 'Email',
            key: 'email_contato',
            sortable: true,
          },
          {
            label: 'Criação',
            key: 'created_at',
            sortable: true,
            formatter: dateTimeFormatter,
          },
          {
            label: 'Status',
            key: 'aceito',
            sortable: true,
            formatter: conviteStatusFormater,
          },
          {
            label: 'Grupo econômico',
            key: 'grupo_economico_nome',
            sortable: true,
          },
          {
            label: 'Último envio',
            key: 'ultimo_envio',
            sortable: true,
            formatter: dateTimeFormatter,
          },
        ],
        filters: [
          {
            category: 'Por período',
            fields: [
              {
                label: 'Criação',
                key: 'created_at',
                type: 'date',
              },
              {
                label: 'Último envio',
                key: 'ultimo_envio',
                type: 'date',
              },
            ],
          },
          {
            category: 'Por parâmetro',
            fields: [
              {
                label: 'Status',
                key: 'status',
                type: 'select',
                options: [
                  { label: 'Aceito', value: '0' },
                  { label: 'Pendente', value: '1' },
                ],
              },
            ],
          },
        ],
        actionsForSelectedItems: [
          {
            label: 'Excluir',
            icon: 'Trash2Icon',
            variant: 'flat-primary',
            disabled: true,
            event: 'excluir-convite',
          },
          {
            label: 'Enviar',
            variant: 'outline-primary',
            disabled: true,
            event: 'enviar-convite',
          },
        ],
        actions: [
          {
            label: 'copyConvite',
            icon: 'icon-copy',
            action: 'copy',
            name: 'copyInviteToken',
          },
        ],
      },
    },
  },
  {
    path: '/admin/convites/novo',
    name: 'admin-novo-convite',
    component: () => import('@/views/admin/convite/pages/novoConvite.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Novo Convite',
    },
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/admin/economicGroup/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Grupo econômico',
      indexObject: {
        serviceUrl: 'backoffice/grupos_economicos/details',
        datatableSearchable: true,
        showExcelExportButton: true,
        fields: [
          {
            label: 'Grupo Econômico',
            key: 'grupo_economico',
            sortable: true,
            formatter: economicGroupNameLink,
          },
          {
            label: 'Empresas no grupo',
            key: 'empresas_no_grupo',
            sortable: true,
          },
          {
            label: 'Empresas aprovadas',
            key: 'empresas_aprovadas',
            sortable: true,
          },
          {
            label: 'Pendências',
            key: 'pendencias',
            sortable: true,
          },
        ],
      },
    }
  }
]
